<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">관리자</a></li>
                        <li class="breadcrumb-item">
                            <a href="javascript:">{{ divisions.title }}</a>
                        </li>
                    </ol>
                </nav>
                <h2 class="page-title">{{ divisions.title }}</h2>
                <div class="page-title-bottom"></div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-form-container">
                    <div>
                        <div class="form-fields">
                            <div class="form-field">
                                <label class="form-label">섬네일 입력</label>
                                <div class="form-file">
                                    <label for="inp_file" class="form-file-label form-file-thumbnail" :class="{'swcc-background-transparent': thumbnail.path}">
                                        <input ref="thumbnailFile" type="file" class="form-file-input" id="inp_file" @change="setThumbnail" />
                                        <img v-if="thumbnail.path" :src="thumbnail.path" alt="" />
                                        <i v-else class="icon-thumbnail-placeholder"></i>
                                    </label>
                                </div>
                            </div>

                            <div class="form-field">
                                <label class="form-label">타이틀</label>
                                <input v-model.trim="params.boardNm" type="text" class="form-input" placeholder="타이틀을 입력해주세요." />
                            </div>

                            <div class="form-field">
                                <label class="form-label">수행기간 입력</label>
                                <input v-model.trim="params.boardSubNm" type="text" class="form-input" placeholder="20xx.xx - 20xx.xx" />
                            </div>

                            <div class="form-field">
                                <label class="form-label">첨부파일</label>
                                <div class="form-file-container" style="height: initial; min-height: 59px">
                                    <label v-if="attachments.length === 0" class="form-label" style="padding: 0">첨부파일을 등록해주세요.</label>
                                    <div v-else class="file-list" style="flex-wrap: wrap">
                                        <div v-for="(item, index) in attachments" class="file" :key="index">
                                            <span class="file-text">{{ item.fileNm }}</span>
                                            <button class="btn-delete" @click="removeAttachment(index)"><i class="icon-delete"></i></button>
                                        </div>
                                    </div>

                                    <div class="form-file">
                                        <input
                                            ref="attachmentFile"
                                            type="file"
                                            class="form-file-input"
                                            id="board_attachment"
                                            multiple
                                            @change="selectAttachment" />
                                        <label for="board_attachment" class="form-file-label text-primary">파일찾기</label>
                                    </div>
                                </div>
                            </div>

                            <!-- field:내용 입력 -->
                            <div class="form-field">
                                <label class="form-label">내용 입력</label>
                                <!-- [!DEV] 에디터 영역, 높이 임의 설정 -->
                                <div class="editor-container swcc-editor-container" style="height: 957px">
                                    <div v-show="false" ref="pageEditor"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side bottom-side-fluid">
                        <div class="btn-actions">
                            <button v-if="bno > 0" class="btn-action btn-text" @click="deleteBoard"><span class="text text-pink">삭제</span></button>
                            <button class="btn-action btn-text" @click="saveBoard">
                                <span class="text">{{ bno > 0 ? '수정' : '등록' }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>
<style></style>

<script>
import {computed, onMounted, onUnmounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {collectionCheck, getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {setPageEditor} from '@/assets/js/editor/editor.utils';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {validateImageMineType, validateParams} from '@/assets/js/common.validate';
import {domainCode} from '@/assets/js/domain.code';

export default {
    name: 'BoardEdit',
    components: {},
    setup: function () {
        showLoading();

        const route = useRoute();
        const router = useRouter();
        const pageEditor = ref(null);
        const session = computed(getAuthSession);
        const bno = computed(() => (route.params.bno ? parseInt(route.params.bno) : bno));

        const divisions = reactive({
            key: computed(() => route.name.replace('Edit', '').toLowerCase()),
            listName: computed(() => route.name.replace('Edit', '')),
            title: computed(() => {
                if (route.name.includes('Consulting')) {
                    return 'AI ・ 데이터 컨설팅';
                } else if (route.name.includes('Conversion')) {
                    return 'SW 융합사례';
                } else if (route.name.includes('Visualization')) {
                    return '시각화 분석사례';
                } else if (route.name.includes('Analytics')) {
                    return '애널리틱 분석사례';
                }
                return '';
            }),
            boardTypeDcd: computed(() => {
                if (route.name.includes('Consulting')) {
                    return domainCode.BOARD_TYPE_CONSULTING;
                } else if (route.name.includes('Conversion')) {
                    return domainCode.BOARD_TYPE_CONVERSION;
                } else if (route.name.includes('Visualization')) {
                    return domainCode.BOARD_TYPE_VISUALIZATION;
                } else if (route.name.includes('Analytics')) {
                    return domainCode.BOARD_TYPE_ANALYTICS;
                }
                return null;
            }),
            messages: computed(() => {
                if (route.name.includes('Consulting')) {
                    return {
                        confirm: 'AI ・ 데이터 컨설팅을',
                        complete: 'AI ・ 데이터 컨설팅이',
                    };
                } else if (route.name.includes('Conversion')) {
                    return {
                        confirm: 'SW 융합사례를',
                        complete: 'SW 융합사례가',
                    };
                } else if (route.name.includes('Visualization')) {
                    return {
                        confirm: '시각화 분석사례를',
                        complete: '시각화 분석사례가',
                    };
                } else if (route.name.includes('Analytics')) {
                    return {
                        confirm: '애널리틱 분석사례를',
                        complete: '애널리틱 분석사례가',
                    };
                }
                return {};
            }),
        });

        // let haveToSaveImage = false;
        const params = reactive({
            bno: 0,
            boardNm: '',
            boardSubNm: '',
            boardContents: '',
            boardThumbnail: null,
        });

        // Thumbnail
        const thumbnailFile = ref(null);
        const thumbnail = reactive({
            path: '',
            file: null,
        });

        const imgReader = new FileReader();
        imgReader.onload = event => {
            thumbnail.path = event.target.result;
        };

        const setThumbnail = () => {
            if (thumbnailFile.value.files && thumbnailFile.value.files.length > 0) {
                if (validateImageMineType(thumbnailFile.value.files[0].type)) {
                    thumbnail.file = thumbnailFile.value.files[0];
                    // 썸 네일 처리
                    imgReader.readAsDataURL(thumbnail.file);
                } else {
                    alert('이미지 파일을 등록해주세요.');
                }
            }
            thumbnailFile.value.value = null;
        };

        const attachmentFile = ref(null);
        const attachments = ref([]);

        const selectAttachment = () => {
            if (attachmentFile.value.files && attachmentFile.value.files.length > 0) {
                for (let i = 0; i < attachmentFile.value.files.length; i++) {
                    attachments.value.push({
                        fileNm: attachmentFile.value.files[i].name,
                        fileSize: attachmentFile.value.files[i].size,
                        fileExtension: attachmentFile.value.files[i].name.substring(attachmentFile.value.files[i].name.lastIndexOf('.') + 1),
                        binary: attachmentFile.value.files[i],
                    });
                }
            }
            attachmentFile.value.value = null;
        };

        const removes = ref([]);

        const removeAttachment = index => {
            if (attachments.value[index].fileNo > 0) removes.value.push(attachments.value[index]);
            attachments.value.splice(index, 1);
        };

        // Editor
        let tryCount = 0;
        let instance = null;

        const setEditorInstance = editor => {
            instance = editor;
            editor.setData(params.boardContents);
            hideLoading();
        };

        const getBoard = () => {
            if (bno.value > 0) {
                ApiService.get('/v1/boards', bno.value)
                    .then(res => {
                        if (lengthCheck(res)) {
                            const item = getItem(res);
                            if (item.boardTypeDcd === divisions.boardTypeDcd) {
                                setParams(params, item);
                                if (params.boardThumbnail) thumbnail.path = params.boardThumbnail;
                                // attachments 셋
                                // if ()
                                if (collectionCheck(item.boardFiles, 'fileNo')) {
                                    attachments.value.push(...item.boardFiles);
                                }
                            }
                        }
                        setPageEditor(pageEditor.value, tryCount, setEditorInstance, `/v1/${divisions.key}/uploads`);
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                    });
            }
        };

        const validateKeys = computed(() => {
            return {
                boardNm: '타이틀을 입력해주세요.',
                boardSubNm: '수행기간을 입력해주세요.',
                boardContents: '내용을 입력해주세요.',
            };
        });

        const saveBoard = async () => {
            params.boardContents = instance ? instance.getData() : params.boardContents;
            if (validateParams(validateKeys.value, params, false)) {
                const actionText = params.bno > 0 ? '수정' : '등록';
                if (confirm(`${divisions.messages.confirm} ${actionText}하시겠습니까?`)) {
                    showLoading();
                    try {
                        if (thumbnail.file !== null) {
                            const imageRes = await ApiService.upload(`/v1/${divisions.key}/uploads`, [thumbnail.file]);
                            if (imageRes.url) {
                                params.boardThumbnail = imageRes.url;
                            }
                        }

                        const saveParams = {...params, boardTypeDcd: divisions.boardTypeDcd};
                        const saveAction = params.bno > 0 ? ApiService.update('/v1/boards', params.bno, saveParams) : ApiService.post('/v1/boards', saveParams);
                        const res = await saveAction;
                        if (isSuccess(res)) {
                            if (res.bno > 0) params.bno = res.bno;
                            // attachment 등록

                            for (let i = 0; i < attachments.value.length; i++) {
                                if (attachments.value[i].binary) {
                                    const uploadRes = await ApiService.upload(`/v1/boards/${divisions.key}/${params.bno}/files/uploads`, [
                                        attachments.value[i].binary,
                                    ]);
                                    if (uploadRes && uploadRes.uploadKey) {
                                        console.log(uploadRes.uploadKey);
                                        attachments.value[i].uploadKey = uploadRes.uploadKey;
                                    }
                                }
                            }

                            const adds = attachments.value
                                .filter(x => !x.fileNo && x.uploadKey)
                                .map(x => ({
                                    fileNm: x.fileNm,
                                    fileExtension: x.fileExtension,
                                    fileSize: x.fileSize,
                                    uploadKey: x.uploadKey,
                                }));

                            if (adds.length > 0 || removes.value.length > 0) {
                                // 첨부파일 또는 삭제될 첨부파일이 있을 경우 처리
                                await ApiService.post(`/v1/boards/${params.bno}/files`, {adds, removes: removes.value});
                            }
                            // const attachmentRes = await ApiService.post(`/v1/boards/${divisions.key}/${params.bno}/files`);
                            alert(`${divisions.messages.complete} ${actionText}되었습니다.`);
                            await router.push({name: `${divisions.listName}View`, params: {bno: params.bno}});
                        } else {
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        }

                        hideLoading();
                    } catch (e) {
                        console.error(e);
                        hideLoading();
                        alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                    }
                }
            }
        };

        const deleteBoard = () => {
            if (confirm(`${divisions.messages.confirm} 삭제하시겠습니까?`)) {
                showLoading();
                ApiService.delete('/v1/boards', bno.value)
                    .then(res => {
                        hideLoading();
                        if (isSuccess(res)) {
                            if (res.bno > 0) params.bno = res.bno;
                            alert(`${divisions.messages.complete} 삭제되었습니다.`);
                            router.push({name: divisions.listName});
                        } else {
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        }
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                        alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                    });
            }
        };

        onMounted(() => {
            if (session.value.manager) {
                if (bno.value > 0) {
                    getBoard();
                } else {
                    setPageEditor(pageEditor.value, tryCount, setEditorInstance, `/v1/${divisions.key}/uploads`);
                }
            } else {
                hideLoading();
                router.push({name: divisions.listName});
            }
        });

        onUnmounted(() => {
            if (instance !== null) {
                instance.destroy();
            }
        });

        return {
            bno,
            pageEditor,
            params,
            thumbnailFile,
            thumbnail,
            attachmentFile,
            attachments,
            divisions,
            setThumbnail,
            selectAttachment,
            removeAttachment,
            saveBoard,
            deleteBoard,
            timestampToDateFormat,
            domainCode,
        };
    },
};
</script>
